import React, {useState} from "react";
import {customModalStyles, GOOGLE_API_KEY, server} from "../../const"
import Modal from "react-modal"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";

export default function EditAddress({modalIsOpen,currentOrder,setShowModal,onFinish}) {
    const token = localStorage.getItem('token')

    const [addLoading, setAddLoading] = useState(false)
    const [address, setAddress] = useState(currentOrder ? currentOrder.address : '')
    const [searchAddress, setSearchAddress] = useState('')
    const [coordinates, setCoordinates] = useState({})


    const editAddress = async () => {
        setAddLoading(true)

        fetch(server + '/client/orders/updateAddress', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                address: address,
                coordinates: coordinates,
                order: currentOrder._id
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setAddress('')
                setSearchAddress('')
                setCoordinates({})
                setShowModal(false)
                onFinish()
            } else {
                toast.warning('A aparut o eroare!');
            }
            setAddLoading(false)

        }).catch(e => {
            setAddLoading(false)
            toast.warning('A aparut o eroare!');
        });
    }

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {
                }}
                style={customModalStyles}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => {
                    setShowModal(false)
                    // onClose()
                }}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Modifică adresa</h2>

                <div className={"form-group"}>
                    <label className={"mt-2"}>Adresa
                        <ReactGoogleMapLoader
                            params={{
                                key: GOOGLE_API_KEY,
                                libraries: "places,geocode",
                            }}
                            render={googleMaps =>
                                googleMaps && (
                                    <ReactGooglePlacesSuggest
                                        googleMaps={googleMaps}
                                        autocompletionRequest={{
                                            input: searchAddress,
                                            // Optional options
                                            // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                                        }}
                                        // Optional props
                                        onNoResult={() => {}}
                                        onSelectSuggest={(geocodedPrediction, originalPrediction) => {
                                            setSearchAddress('')
                                            setAddress(geocodedPrediction.formatted_address)
                                            setCoordinates({
                                                lat: geocodedPrediction.geometry.location.lat(),
                                                lng: geocodedPrediction.geometry.location.lng()
                                            })
                                        }}
                                        onStatusUpdate={(e) => {}}
                                        textNoResults="Nu am găsit rezultate" // null or "" if you want to disable the no results item
                                        customRender={prediction => (
                                            <div className="customWrapper">
                                                {prediction
                                                    ? prediction.description
                                                    : "Nu am găsit rezultate"}
                                            </div>
                                        )}
                                    >
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e.target.value)
                                                setSearchAddress(e.target.value)
                                            }}
                                        />
                                    </ReactGooglePlacesSuggest>)
                            }
                        />
                    </label>
                </div>

                <button type={"button"} className={"btn btn-success w-100 mt-5"}
                        disabled={addLoading}
                        onClick={() => editAddress()}>
                    {
                        addLoading ?
                            <SmallLoadingComp/>
                            :
                            'Salvează'
                    }
                </button>
            </Modal>

        </>
    );
}
