import React, {useEffect, useState} from "react";
import {GOOGLE_API_KEY, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMapPin} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import moment from "moment";
import {
    displayBadgePrefixes,
    extractCodesPrefixes,
    getOrderStatusBadge,
    truncate
} from "../../components/utils"
import LoadingComp from "../../components/loaders/LoadingComp";
import OrderModal from "../../components/OrderModal/OrderModal";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";

export default function OrdersToday() {

    const token = localStorage.getItem('token')
    const [orders, setOrders] = useState([])

    const [loading, setLoading] = useState(false)
    const [includePickUpOrders, setIncludePickUpOrders] = useState(true)

    const [modalIsOpen, setIsOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState({})
    const [googleMapsLink, setGoogleMapsLink] = useState('')

    const [address, setAddress] = useState()
    const [searchAddress, setSearchAddress] = useState('')
    const [coordinates, setCoordinates] = useState({})
    const [addressDestination, setAddressDestination] = useState()
    const [searchAddressDestination, setSearchAddressDestination] = useState('')
    const [coordinatesDestination, setCoordinatesDestination] = useState({})
    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setAddress(json.address)
                setAddressDestination(json.address)
                setSearchAddress(json.address)
                setSearchAddressDestination(json.address)
                setCoordinates(json.coordinates)
                setCoordinatesDestination(json.coordinates)
                getOrders()
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }
    const getOrders = () => {

        setLoading(true)
        fetch(server + '/client/orders?type=today_delivery_pickup&origin=' + address + '&destination=' + addressDestination +'&pickup=' + includePickUpOrders, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json && json['orders'].length > 0) {
                let orderedList = [...json['orders']].sort((a, b) => parseInt(a.order) < parseInt(b.order) ? -1 : 1)

                let link = 'https://www.google.com/maps/dir/' + coordinates.lat + ',' + coordinates.lng + '/';
                for (let i = 0; i < orderedList.length; i++) {
                    if (orderedList[i].coordinates && orderedList[i].order < 100)
                        link += orderedList[i].coordinates.lat + ',' + orderedList[i].coordinates.lng + '/'
                }
                link += coordinatesDestination.lat + ',' + coordinatesDestination.lng + '/'

                setOrders(orderedList)
                setGoogleMapsLink(link)
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
                setLoading(false)
            }

        }).catch(e => {
            toast.warning('A aparut o eroare!');
            setLoading(false)
        });
    }

    useEffect(() => {
        getClient()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={"container"}>


            <h2 className={"list-page-title"}>Comenzi de ridicat/livrat (maxim 23 de comenzi)</h2>

            <form onSubmit={(e) => {
                e.preventDefault()
                getOrders()
            }}>
                <div className="form-group">
                    <label>
                        <input type={"checkbox"} value={"1"}
                               checked={includePickUpOrders}
                               onChange={() => setIncludePickUpOrders(!includePickUpOrders)}/>  Include comenzile de ridicat
                    </label>
                    <label>Adresă de plecare

                        <ReactGoogleMapLoader
                            params={{
                                key: GOOGLE_API_KEY,
                                libraries: "places,geocode",
                            }}
                            render={googleMaps =>
                                googleMaps && (
                                    <ReactGooglePlacesSuggest
                                        googleMaps={googleMaps}
                                        autocompletionRequest={{
                                            input: searchAddress,
                                            // Optional options
                                            // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                                        }}
                                        // Optional props
                                        onNoResult={() => {
                                        }}
                                        onSelectSuggest={(geocodedPrediction, originalPrediction) => {
                                            setSearchAddress('')
                                            setAddress(geocodedPrediction.formatted_address)
                                            setCoordinates({
                                                lat: geocodedPrediction.geometry.location.lat(),
                                                lng: geocodedPrediction.geometry.location.lng()
                                            })
                                        }}
                                        onStatusUpdate={(e) => {
                                        }}
                                        textNoResults="Nu am găsit rezultate" // null or "" if you want to disable the no results item
                                        customRender={prediction => (
                                            <div className="customWrapper">
                                                {prediction
                                                    ? prediction.description
                                                    : "Nu am găsit rezultate"}
                                            </div>
                                        )}
                                    >
                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e.target.value)
                                                setSearchAddress(e.target.value)
                                            }}
                                        />
                                    </ReactGooglePlacesSuggest>)
                            }
                        />

                    </label>


                </div>
                <div className="form-group">
                    <label>Adresă de destinație

                        <ReactGoogleMapLoader
                            params={{
                                key: GOOGLE_API_KEY,
                                libraries: "places,geocode",
                            }}
                            render={googleMaps =>
                                googleMaps && (
                                    <ReactGooglePlacesSuggest
                                        googleMaps={googleMaps}
                                        autocompletionRequest={{
                                            input: searchAddressDestination,
                                            // Optional options
                                            // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                                        }}
                                        // Optional props
                                        onNoResult={() => {
                                        }}
                                        onSelectSuggest={(geocodedPrediction, originalPrediction) => {
                                            setSearchAddressDestination('')
                                            setAddressDestination(geocodedPrediction.formatted_address)
                                            setCoordinatesDestination({
                                                lat: geocodedPrediction.geometry.location.lat(),
                                                lng: geocodedPrediction.geometry.location.lng()
                                            })
                                        }}
                                        onStatusUpdate={(e) => {
                                        }}
                                        textNoResults="Nu am găsit rezultate" // null or "" if you want to disable the no results item
                                        customRender={prediction => (
                                            <div className="customWrapper">
                                                {prediction
                                                    ? prediction.description
                                                    : "Nu am găsit rezultate"}
                                            </div>
                                        )}
                                    >
                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            value={addressDestination}
                                            onChange={(e) => {
                                                setAddressDestination(e.target.value)
                                                setSearchAddressDestination(e.target.value)
                                            }}
                                        />
                                    </ReactGooglePlacesSuggest>)
                            }
                        />

                    </label>


                </div>
                <button type={"submit"} className={"btn btn-primary w-100"}
                >Calculeză ruta
                </button>
            </form>


            {googleMapsLink !== '' &&
                <a target={"_blank"} rel={'noreferrer'} className={"btn-directions"} href={googleMapsLink}>
                    <button type={"button"} className={"btn btn-primary w-100"}><FontAwesomeIcon
                        icon={faMapPin}/> Google maps
                    </button>
                </a>}
            {loading ?
                <LoadingComp/>
                :
                <div className={"row list-page-table"}>
                    <div className={"col-12"}>
                        <div className={"table-responsive"}>
                            {orders.length > 0 && <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Etichetă</th>
                                    <th scope="col">Adresa</th>
                                    <th scope="col">
                                        <div className={"cursor-pointer"}>
                                                    <span>Ridicare
                                                    </span>
                                        </div>

                                    </th>
                                    <th scope="col" style={{minWidth: '85px'}}>
                                        <div className={"cursor-pointer"}>
                                                <span>Livrare
                                                </span>
                                        </div>
                                    </th>
                                    <th scope="col">Covoare</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Mențiuni</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orders.map((order, i) => (
                                        <tr key={i}
                                            className={"tr-link"}
                                            style={(order.order > 100 ? {backgroundColor: 'lightgrey'} : {})}
                                            onClick={() => {
                                                setCurrentOrder(order)
                                                setIsOpen(true)
                                            }}
                                        >
                                            <th scope="row">{order.number}</th>
                                            <td>{order.name}</td>
                                            <td>{order.phone}</td>
                                            <td>{displayBadgePrefixes(extractCodesPrefixes(order.carpets))}</td>
                                            <td>{order.address} {!order.coordinates ? <span className={"badge bg-warning"}>Adresa nu a fost găsită</span> : ''}</td>
                                            <td>{order.datePickup ? moment(order.datePickup).format("DD/MM/YYYY HH:mm") : ''}</td>
                                            <td>{order.dateDelivery ? moment(order.dateDelivery).format("DD/MM/YYYY") + ' ' + order.deliveryInterval : ''}</td>
                                            <td>{order.itemsCount ? order.itemsCount : ''}</td>
                                            <td>{getOrderStatusBadge(order.status)}</td>
                                            <td>{truncate(order.notes, 30)}</td>
                                        </tr>
                                    ))
                                }

                                </tbody>
                            </table>}
                        </div>
                    </div>
                </div>
            }

            <OrderModal
                currentOrder={currentOrder}
                showModal={modalIsOpen}
                setShowModal={setIsOpen}
                onFinish={getOrders}
            />


        </div>
    );
}
