import OrderModalPickup from "./OrderModalPickup";
import OrderModalMeasure from "./OrderModalMeasure";
import OrderModalWash from "./OrderModalWash";
import OrderModalDry from "./OrderModalDry";
import OrderModalDelivery from "./OrderModalDelivery";
import OrderModalCompleted from "./OrderModalCompleted";
import OrderModalInvoiced from "./OrderModalInvoiced";
import OrderModalPaid from "./OrderModalPaid";

export default function OrderModal({
                                       currentOrder,
                                       showModal,
                                       setShowModal,
    onFinish,drivers = []
                                   }) {


    return (<>

            {
                currentOrder.status === 1 ?
                    <OrderModalPickup
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                        drivers={drivers}
                    />
                    : null
            }

            {
                currentOrder.status === 2 ?
                    <OrderModalMeasure
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                    />
                    : null
            }

            {
                currentOrder.status === 3 ?
                    <OrderModalWash
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                    />
                    : null
            }

            {
                currentOrder.status === 4 ?
                    <OrderModalDry
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                    />
                    : null
            }

            {
                currentOrder.status === 5 ?
                    <OrderModalDelivery
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                        drivers={drivers}
                    />
                    : null
            }

            {
                currentOrder.status === 6 ?
                    <OrderModalCompleted
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                    />
                    : null
            }

            {
                currentOrder.status === 7 ?
                    <OrderModalInvoiced
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                    />
                    : null
            }

            {
                currentOrder.status === 8 ?
                    <OrderModalPaid
                        currentOrder={currentOrder}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onFinish={onFinish ? onFinish : () => {}}
                    />
                    : null
            }

        </>
    );
}
