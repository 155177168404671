import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown, faCaretUp, faEnvelope, faSort, faTimes} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import moment from "moment";
import {
    displayBadgePrefixes,
    extractCodesPrefixes,
    getMobileOperatingSystem,
    getOrderStatusBadge,
    isDatePassed, roundNumber, truncate
} from "../../components/utils"
import LoadingComp from "../../components/loaders/LoadingComp";
import Modal from "react-modal";
import OrderModal from "../../components/OrderModal/OrderModal";
import DatePicker from "react-datepicker";

export default function OrdersAll() {

    const colorOrderSource1 = localStorage.getItem('color_order_source_1')
    const token = localStorage.getItem('token')

    const persistedDateStart = localStorage.getItem('orders_all_date_start')
    const persistedDateEnd = localStorage.getItem('orders_all_date_end')

    const defaultDateStart = persistedDateStart ?
        moment(new Date(persistedDateStart)).set('hour', 0).set('minute', 0).format("YYYY-MM-DDTHH:mm")
        :
        moment().set('hour', 0).set('minute', 0).subtract(7, 'days').format("YYYY-MM-DDTHH:mm")

    const defaultDateEnd = persistedDateEnd ?
        moment(new Date(persistedDateEnd)).set('hour', 23).set('minute', 59).format("YYYY-MM-DDTHH:mm")
        :
        moment().set('hour', 23).set('minute', 59).format("YYYY-MM-DDTHH:mm")

    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0)
    const [sortParam, setSortParam] = useState('datePickup')
    const [sortOrder, setSortOrder] = useState('ascending')
    const [dateStart, setDateStart] = useState(defaultDateStart)
    const [dateEnd, setDateEnd] = useState(defaultDateEnd)

    const [deliveryDate, setDeliveryDate] = useState('')
    const [deliveryInterval, setDeliveryInterval] = useState('')

    const [type, setType] = useState('all')

    const [loading, setLoading] = useState(true)

    const [modalIsOpen, setIsOpen] = useState(false);
    const [smsModalShow, setSmsModalShow] = useState(false)
    const [currentOrder, setCurrentOrder] = useState({})

    const [company, setCompany] = useState({})

    const [search, setSearch] = useState('')
    const [drivers, setDrivers] = useState([])

    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCompany(json)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const getOrders = (requestedPage) => {
        fetch(server + '/client/orders?type=' + type + '&sortParam=' + sortParam + '&sortOrder=' + sortOrder + (search.length > 0 ? '&search=' + search : '') + '&start=' + dateStart + '&end=' + dateEnd + '&page=' + requestedPage, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                if (requestedPage > 1) {
                    setOrders([...orders, ...json['orders']])
                } else {
                    setOrders(json['orders'])
                }
                setPage(requestedPage)
                setTotalOrders(json['total'])
                getDrivers()
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        getClient()
        getOrders(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getOrders(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, sortParam, sortOrder, type, dateStart, dateEnd])

    const getDrivers = () => {
        fetch(server + '/client/employees', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setDrivers(json.filter((item) => item.role === 'driver'))
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }
    useEffect(() => {
        setDeliveryDate(currentOrder.dateDelivery ? moment(new Date(currentOrder.dateDelivery)).format('YYYY-MM-DD') : '')
        setDeliveryInterval(currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08:00 - 10:00')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder])

    const updateDeliveryDate = () => {

        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                dateDelivery: deliveryDate,
                deliveryInterval: deliveryInterval
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            getOrders()
        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        if (currentOrder && currentOrder._id) {
            updateDeliveryDate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryDate, deliveryInterval])

    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>
                    <div className="float-end">
                        <input type="text"
                               className="form-control input-search"
                               placeholder="Caută comandă"
                               value={search}
                               onChange={(e) => {
                                   setSearch(e.target.value)
                               }
                               }
                        />
                    </div>

                    <h2 className={"list-page-title"}>Comenzi</h2>

                    <div className={"mt-4"}>
                        <label className={"me-4"}>De la
                            <DatePicker
                                className={"form-control"}
                                selected={new Date(dateStart)}
                                onChange={(date) => {
                                    setDateStart(moment(date).set('hour', 0).set('minute', 0).format('YYYY-MM-DDTHH:mm'))
                                    localStorage.setItem('orders_all_date_start', moment(date).set('hour', 0).set('minute', 0).format('YYYY-MM-DDTHH:mm'))
                                }}
                                dateFormat="dd/MM/yyyy"
                                minuteInterval={10}
                            />
                        </label>
                        <label>Până la
                            <DatePicker
                                className={"form-control"}
                                selected={new Date(dateEnd)}

                                onChange={(date) => {
                                    setDateEnd(moment(date).set('hour', 23).set('minute', 59).format('YYYY-MM-DDTHH:mm'))
                                    localStorage.setItem('orders_all_date_end', moment(date).set('hour', 23).set('minute', 59).format('YYYY-MM-DDTHH:mm'))
                                }}
                                dateFormat="dd/MM/yyyy"
                                minuteInterval={10}
                            />
                        </label>
                    </div>
                    <div style={{maxWidth: '200px'}} className={"mt-4"}>
                        <label>Stare comenzi</label>
                        <select className={"form-control"} value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={"all"}>Toate</option>
                            <option value={"pickup"}>De colectat</option>
                            <option value={"measure"}>De măsurat</option>
                            <option value={"wash"}>De spălat</option>
                            <option value={"dry"}>De uscat</option>
                            <option value={"delivery"}>De livrat</option>
                            <option value={"completed"}>Finalizate</option>

                            {
                                company && company.activateInvoicing ?
                                    <>
                                        <option value={"not-paid"}>Neîncasate</option>
                                        <option value={"paid"}>Încasate</option>
                                    </>
                                    : null
                            }
                        </select>
                    </div>

                    <div className={"row list-page-table"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">Etichetă</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Telefon</th>
                                        <th scope="col">Adresa</th>
                                        <th scope="col">Șofer</th>
                                        <th scope="col">
                                            {
                                                sortParam === 'datePickup' ?
                                                    <div className={"cursor-pointer"} onClick={() => {
                                                        setSortParam('datePickup')
                                                        if (sortOrder === 'ascending') {
                                                            setSortOrder('descending')
                                                        } else {
                                                            setSortOrder('ascending')
                                                        }
                                                    }
                                                    }>
                                                    <span className={"text-decoration-underline"}>Ridicare {
                                                        sortOrder === 'ascending' ?
                                                            <FontAwesomeIcon icon={faCaretUp}/>
                                                            :
                                                            <FontAwesomeIcon icon={faCaretDown}/>
                                                    }
                                                    </span>
                                                    </div>
                                                    :
                                                    <div className={"cursor-pointer"}
                                                         onClick={() => setSortParam('datePickup')}>
                                                        <span>Ridicare <FontAwesomeIcon icon={faSort}/></span>
                                                    </div>
                                            }
                                        </th>
                                        <th scope="col" style={{minWidth: '85px'}}>
                                            {
                                                sortParam === 'dateDelivery' ?
                                                    <div className={"cursor-pointer"} onClick={() => {
                                                        setSortParam('dateDelivery')
                                                        if (sortOrder === 'ascending') {
                                                            setSortOrder('descending')
                                                        } else {
                                                            setSortOrder('ascending')
                                                        }
                                                    }
                                                    }>
                                                    <span className={"text-decoration-underline"}>Livrare {
                                                        sortOrder === 'ascending' ?
                                                            <FontAwesomeIcon icon={faCaretUp}/>
                                                            :
                                                            <FontAwesomeIcon icon={faCaretDown}/>
                                                    }
                                                    </span>
                                                    </div>
                                                    :
                                                    <div className={"cursor-pointer"}
                                                         onClick={() => setSortParam('dateDelivery')}>
                                                        <span>Livrare <FontAwesomeIcon icon={faSort}/></span>
                                                    </div>
                                            }
                                        </th>
                                        <th scope="col">Covoare</th>
                                        <th scope="col">Mențiuni</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        orders ? orders.map((order, i) => (
                                                <tr key={i}
                                                    className={"tr-link " + (isDatePassed(order.dateDelivery) && order.status !== 6 ? 'order-delivery-date-passed' : '')}
                                                    style={(order.source === 1 && order.status !== 6 ? {backgroundColor: colorOrderSource1} : {})}
                                                    onClick={() => {
                                                        setCurrentOrder(order);
                                                        setIsOpen(true)
                                                    }}
                                                >
                                                    <th scope="row">{order.number}</th>
                                                    <td>{order.name}</td>
                                                    <td>{displayBadgePrefixes(extractCodesPrefixes(order.carpets))}</td>
                                                    <td>{getOrderStatusBadge(order.status)}</td>
                                                    <td>{order.phone}</td>
                                                    <td>{order.address}</td>
                                                    <td>{order.driver !== 0 && order.driver !== undefined && drivers.find((item) => item._id === order.driver) ?
                                                    drivers.find((item) => item._id === order.driver).name : ''}</td>

                                                    <td>{order.datePickup ? moment(order.datePickup).format("DD/MM/YYYY HH:mm") : ''}</td>
                                                    <td>{order.dateDelivery ? moment(order.dateDelivery).format("DD/MM/YYYY") + ' ' + order.deliveryInterval : ''}</td>
                                                    <td>{order.itemsCount ? order.itemsCount : ''}</td>
                                                    <td>{truncate(order.notes, 30)}</td>
                                                </tr>
                                            ))
                                            : null
                                    }

                                    </tbody>
                                </table>

                                {
                                    orders.length < totalOrders ?
                                        <div className={"mb-5 w-100 text-center"}>
                                            <button type={"button"} className={"btn btn-secondary"}
                                                    onClick={() => getOrders(page + 1)}>Vezi mai multe
                                            </button>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>

                </>
            }


            <OrderModal
                currentOrder={currentOrder}
                showModal={modalIsOpen}
                setShowModal={setIsOpen}
                onFinish={getOrders}
                drivers={drivers}
            />

            <Modal
                isOpen={smsModalShow}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setSmsModalShow(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setSmsModalShow(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Trimite sms</h2>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Data livrare
                            <input type="date" className="form-control no-width"
                                   value={deliveryDate}
                                   onChange={(e) => setDeliveryDate(e.target.value)}/>
                        </label>
                    </div>
                    {
                        deliveryDate ?
                            <div className={"col-12"}>
                                <label>Interval livrare
                                    <select value={deliveryInterval} className={"form-control"}
                                            onChange={(e) => setDeliveryInterval(e.target.value)}>
                                        <option value={"08:00 - 10:00"}>08:00 - 10:00</option>
                                        <option value={"10:00 - 12:00"}>10:00 - 12:00</option>
                                        <option value={"12:00 - 15:00"}>12:00 - 15:00</option>
                                        <option value={"15:00 - 18:00"}>15:00 - 18:00</option>
                                        <option value={"18:00 - 20:00"}>18:00 - 20:00</option>
                                    </select>
                                </label>
                            </div>
                            : null
                    }

                    <div className={"col-12"}>
                        {
                            company && company.templateSms1 && currentOrder && currentOrder.status >= 3 ?

                                <a href={
                                    getMobileOperatingSystem() === 'Android' ?
                                        encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]', (currentOrder.carpets && currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0] : '')))
                                        :
                                        encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]', (currentOrder.carpets && currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0] : '')))
                                }>
                                    <button
                                        type={"button"}
                                        className={"btn btn-secondary w-100 mt-3"}>
                                        <FontAwesomeIcon icon={faEnvelope}/> Sms - comandă măsurată
                                    </button>
                                </a>
                                : null
                        }
                        {
                            company && company.templateSms2 && currentOrder ?

                                <a href={
                                    getMobileOperatingSystem() === 'Android' ?
                                        encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms2.replace('[data]', deliveryDate ? moment(new Date(deliveryDate)).format("DD/MM/YYYY") + ' ' + deliveryInterval : '').replace('[link]', 'https://app.totalcarpet.ro/changeDeliveryDate/' + company._id + '/' + currentOrder._id))
                                        :
                                        encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms2.replace('[data]', deliveryDate ? moment(new Date(deliveryDate)).format("DD/MM/YYYY") + ' ' + deliveryInterval : '').replace('[link]', 'https://app.totalcarpet.ro/changeDeliveryDate/' + company._id + '/' + currentOrder._id))
                                }>
                                    <button
                                        type={"button"}
                                        className={"btn btn-secondary w-100 mt-3"}>
                                        <FontAwesomeIcon icon={faEnvelope}/> Sms - gata de livrare
                                    </button>
                                </a>
                                : null
                        }

                        {
                            company && company.templateSms3 ?

                                <a href={
                                    getMobileOperatingSystem() === 'Android' ?
                                        encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms3.replace('[interval]', currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08-20'))
                                        :
                                        encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms3.replace('[interval]', currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08-20'))
                                }>
                                    <button
                                        type={"button"}
                                        className={"btn btn-secondary w-100 mt-3"}>
                                        <FontAwesomeIcon icon={faEnvelope}/> Sms - livrăm astăzi
                                    </button>
                                </a>
                                : null
                        }
                    </div>
                </div>

            </Modal>

        </div>
    );
}
